<template>
  <div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="primary-btn text-capitalize mr-2"
        outlined
        rounded
        @click="openRemoveDidModal"
        v-if="selectedNumberSubscriptionData.length != 0"
        >Remove DID</v-btn
      >
      <v-btn
        class="primary-btn text-capitalize mr-2"
        to="purchasedid"
        outlined
        rounded
        >Order New DID</v-btn
      >
    </v-card-actions>

    <v-row>
      <v-col cols="12">
        <v-divider></v-divider>
        <v-data-table
          :headers="header"
          :items="numberSubscriptionData"
          :server-items-length="totalNumberSubscription"
          :options.sync="options"
        >
          <template v-slot:[`item.singleSelect`]="{ item }">
            <v-checkbox
              v-model="item.singleSelect"
              @click="getSelectData(item)"
              :disabled="item.disableSeleted"
            ></v-checkbox>
          </template>
          <template v-slot:[`item.indexNo`]="{ item }">
            <span
              :class="item.didStatus === 'DID_SUSPENDED' ? 'red--text' : ''"
            >
              {{ numberSubscriptionData.indexOf(item) + 1 }}
            </span>
          </template>
          <template v-slot:[`item.country`]="{ item }">
            <span
              :class="item.didStatus === 'DID_SUSPENDED' ? 'red--text' : ''"
            >
              {{ item.country ? item.country : "-" }} <br />
              {{ item.city ? item.city : "-" }}
            </span>
          </template>
          <template v-slot:[`item.didNumber`]="{ item }">
            <span
              :class="item.didStatus === 'DID_SUSPENDED' ? 'red--text' : ''"
            >
              {{ item.didNumber }}
            </span>
          </template>
          <template v-slot:[`item.orderId`]="{ item }">
            <span
              :class="item.didStatus === 'DID_SUSPENDED' ? 'red--text' : ''"
            >
              {{ item.orderId }}
            </span>
          </template>
          <template v-slot:[`item.billingTenurePeriod`]="{ item }">
            <v-select
              :disabled="item.didStatus === 'DID_ACTIVATED' ? false : true"
              :items="billingTenurePeriodList"
              v-model="item.billingTenurePeriod"
              placeholder="Select Option"
              @change="openModal(item, numberSubscriptionData.indexOf(item))"
              class="pt-3"
              filled
              dense
            ></v-select>
          </template>
          <template v-slot:[`item.daysRemaining`]="{ item }">
            <v-avatar class="light-green mb-1" size="18"></v-avatar>

            <span
              v-if="item.daysRemaining < 0"
              :class="item.didStatus === 'DID_SUSPENDED' ? 'red--text' : ''"
            >
              0
            </span>
            <span
              v-else
              :class="item.didStatus === 'DID_SUSPENDED' ? 'red--text' : ''"
            >
              {{ item.daysRemaining }}
            </span>
          </template>

          <template v-slot:[`item.hasSms`]="{ item }">
            <v-btn
              :disabled="
                item.hasSms !== 'Y' || item.didStatus !== 'DID_ACTIVATED'
              "
              :color="
                item.hasSms === 'Y' && item.didStatus === 'DID_ACTIVATED'
                  ? 'success'
                  : 'grey'
              "
              @click="openAssigningEmailDialog(item)"
            >
              SMS Enabled
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    color="info"
                    small
                  >
                    mdi-information
                  </v-icon>
                </template>
                <span>Set/Change Forwarding Email address.</span>
              </v-tooltip>
            </v-btn>
          </template>

          <template v-slot:[`item.callForwarding`]="{ item }">
            <!-- <v-row> -->
            <!-- <v-col cols="12" md="6" class="mx-auto py-0">
                <v-switch
                  v-model="item.autoRenew"
                  value="Y"
                  @change="autoRenewSubscription(item)"
                ></v-switch>
              </v-col> -->
            <!-- </v-row> -->
            <span
              v-if="
                item.didStatus === 'DID_INACTIVE' ||
                  item.didStatus === 'DID_SUSPENDED'
              "
            >
              <v-icon> mdi-cancel </v-icon>
            </span>
            <span v-else>
              <v-icon @click="openAssigningNumberDialog(item)" color="info"
                >forward</v-icon
              >
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <span v-if="item.didStatus != 'DID_SUSPENDED'">
              <v-select
                :items="actionsNOptions"
                v-model="item.actions"
                v-if="
                  item.autoRenew === 'N' && item.didStatus != 'DID_INACTIVE'
                "
                item-text="title"
                item-value="value"
                label="Select action"
                @change="selectBatch(item, item.actions)"
                class="pt-3"
                filled
                dense
              >
              </v-select>
              <v-select
                :items="actionsYOptions"
                v-model="item.actions"
                v-if="item.autoRenew == 'Y' && item.didStatus != 'DID_INACTIVE'"
                item-text="title"
                item-value="value"
                label="Select action"
                @change="selectBatch(item, item.actions)"
                class="pt-3"
                filled
                dense
              >
              </v-select>
              <v-select
                :items="actionsInactiveOptions"
                v-model="item.actions"
                v-if="item.didStatus === 'DID_INACTIVE'"
                item-text="title"
                item-value="value"
                label="Select action"
                @change="selectBatch(item, item.actions)"
                class="pt-3"
                filled
                dense
              >
              </v-select>
            </span>
            <span v-else>
              <v-select
                :items="actionsSuspendedOptions"
                v-model="item.actions"
                item-text="title"
                item-value="value"
                label="Select action"
                @change="selectBatch(item, item.actions)"
                class="pt-3"
                filled
                dense
              >
              </v-select>
            </span>
            <!-- <div
              v-if="
                item.didStatus != 'DID_INACTIVE' &&
                item.didStatus != 'DID_SUSPENDED'
              "
            >
              <v-icon @click="getDeviceItem(item)" color="black"
                >delete_sweep</v-icon
              >
              /
              <v-icon @click="openAssigningNumberDialog(item)" color="info"
                >forward</v-icon
              >
              /
            </div>
            <div v-if="item.didStatus === 'DID_INACTIVE'">
              <v-icon color="black">not_interested</v-icon>
            </div>
            <div v-if="item.didStatus === 'DID_SUSPENDED'">
              <v-icon color="info" @click="openSubscriptionRenewDialog(item)"
                >autorenew</v-icon
              > 
            </div>-->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Billing Tenure Dialog -->
    <v-dialog v-model="confirmActionDialog" persistent max-width="380">
      <v-card>
        <v-card-title class="title">
          <span v-if="showSetBillingTenureBtn">
            Would you like to set billing tenure?</span
          >
          <span v-if="!showSetBillingTenureBtn && !showRenew"
            >Would you like to delete?</span
          >
          <span v-if="showRenew">Are you sure,you want to Renew?</span>
          <br /><br />
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="closeConfirmActionDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="setBillingTenure"
            v-show="showSetBillingTenureBtn"
          >
            Ok
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="deleteDeviceDetails"
            v-show="showdDeleteDeviceBtn"
          >
            Ok
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="deleteFaxboxesDetails"
            v-show="showdFaxboxesBtn"
          >
            Ok
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="subscriptionRenew"
            v-show="showRenew"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete Device Dialog -->
    <v-dialog v-model="deleteDeviceDialog" persistent max-width="440">
      <v-card>
        <v-toolbar color="primary" dark
          >Delete Device
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDeleteDeviceDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  :items="deviceList"
                  prepend-icon="call"
                  v-model="deleteDevice.deviceList"
                  label="Sip details/ regular phn"
                  item-text="name"
                  item-value="id"
                  @change="confirmDeleteDeviceDialog(deleteDevice.deviceList)"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12">
                <v-select
                  :items="faxBoxList"
                  prepend-icon="mail"
                  label="Faxtoemail"
                  v-model="deleteDevice.faxBoxList"
                  item-text="name"
                  item-value="id"
                  @change="confirmFaxBoxListeDialog(deleteDevice.faxBoxList)"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error darken-1"
            text
            @click="deleteDeviceDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Subscription RenewDialog -->
    <v-dialog v-model="showSubscriptionRenewDialog" persistent max-width="700">
      <v-card>
        <v-toolbar color="primary" dark
          ><v-card-title class="px-0">
            Subscription Renew Details
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showSubscriptionRenewDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-0">
          <v-list subheader two-line>
            <v-list-item class="grey lighten-3">
              <v-list-item-content>
                <v-list-item-title
                  ><b>Suspended Period Rental :</b>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-title class="subtitle-1">
                  {{ subscriptionRenewData.expireDayCost }}
                </v-list-item-title>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><b>Renew Charge :</b> </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-title class="subtitle-1">
                  {{ subscriptionRenewData.unitPrice }}
                </v-list-item-title>
              </v-list-item-action>
            </v-list-item>
            <v-list-item class="grey lighten-3">
              <v-list-item-content>
                <v-list-item-title><b>Total Cost :</b> </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-title class="subtitle-1">
                  {{ subscriptionRenewData.totalPrice }}
                </v-list-item-title>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            class="mx-auto px-4"
            dark
            @click="openshowRenewModal"
          >
            Renew
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Assigning Number Dialog-->
    <v-dialog v-model="showAssigningNumberDialog" max-width="700">
      <assigningNumber
        :assigningNumberData="assigningNumberData"
        :cellPhoneDeviceData="cellPhoneDeviceData"
        :sipUriDeviceData="sipUriDeviceData"
        :softPhoneDeviceData="softPhoneDeviceData"
        :faxBoxList="faxBoxList"
        :getForwardDetails="getForwardDetails"
        @closeModal="closeAssignNumberModal"
        @closedAssignedModal="closeOnAssigned"
        @switchServerModal="showSwitchServerModal"
        v-if="getForwardDetails.forwardType == 'NONE'"
      />
      <editAssigningNumberDilaog
        :assigningNumberData="assigningNumberData"
        :cellPhoneDeviceData="cellPhoneDeviceData"
        :sipUriDeviceData="sipUriDeviceData"
        :softPhoneDeviceData="softPhoneDeviceData"
        :faxBoxList="faxBoxList"
        :selectOption="selectOption"
        :username="username"
        :ipAddress="ipAddress"
        :inbound_format="inbound_format"
        @closeModal="closeAssignNumberModal"
        @switchServerModal="showSwitchServerModal"
        @closedOnAssignedModal="closeOnAssigned"
        v-if="getForwardDetails.forwardType != 'NONE'"
      />
    </v-dialog>
    <v-dialog v-model="showAssigningEmailDialog" max-width="700">
      <v-card>
        <v-card-title class="headline"
          >SMS Enabled : SET//Change Forwarding Email address</v-card-title
        >
        <v-card-text>
          <v-form ref="smsForm" v-model="isFormValid">
            <v-text-field
              v-model="emailAddress"
              label="Email Address"
              :rules="emailRules"
              required
            ></v-text-field>
            <v-select
              v-model="hasSmsForEmail"
              :items="['Y', 'N']"
              label="Email Notification"
              required
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="updateSettings"
            :disabled="!isFormValid"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Remove Number Dialog-->
    <v-dialog v-model="showRemoveDid" max-width="700">
      <showRemoveDidDialog
        :particularAssigningNumber="particularAssigningNumberData"
        @close-remove-did-dialog="closeRemoveDidDialog"
      />
    </v-dialog>
    <!-- show Switch Server Modal Box -->
    <v-dialog v-model="showSwitchServerModalBox" max-width="400">
      <showSwitchServerDialog
        @closeSwitchServer="closeSwitchServerModal"
        @removeServer="removeCurrentServer"
      />
    </v-dialog>
    <!-- show Subscription Renew Modal Box -->
    <v-dialog v-model="renewModalBox" max-width="400">
      <subscriptionRenew
        :selectNumberManagerData="selectNumberManagerData"
        @closeRenewModalBox="closeRenewModal"
      />
    </v-dialog>
    <loading :active="isLoading" :loader="loader" />
  </div>
</template>

<script>
import moment from "moment";
import numberManagerAPI from "@/services/numberManagerAPI.js";
import assigningNumber from "./assigningNumberDilaog";
import editAssigningNumberDilaog from "./editAssigningNumberDilaog";
import showRemoveDidDialog from "./removeDidDialog";
import showSwitchServerDialog from "./switchServerDialog";
import subscriptionRenew from "./subscriptionRenew";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "debitDetailsTable",
  props: ["status"],
  components: {
    Loading,
    assigningNumber,
    editAssigningNumberDilaog,
    showRemoveDidDialog,
    showSwitchServerDialog,
    subscriptionRenew,
  },
  data() {
    return {
      showSmsDialog: false,
      emailAddress: "",
      hasSmsForEmail: "Y", // Default to "Y"
      isFormValid: false,
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
      moment: moment,
      confirmActionDialog: false,
      deleteDeviceDialog: false,
      showdDeleteDeviceBtn: false,
      showSetBillingTenureBtn: false,
      showSubscriptionRenewDialog: false,
      showAssigningNumberDialog: false,
      showdFaxboxesBtn: false,
      showRenew: false,
      showRemoveDid: false,
      showSwitchServerModalBox: false,
      renewModalBox: false,
      model: "1",
      isLoading: false,
      loader: "bars",
      singleSelect: false,
      showSelect: null,
      billingTenurePeriodList: [1, 3, 6, 12, 24],
      particularAssigningNumberData: [],
      userDetail: {},
      selectedBilingTenureData: {},
      selectNumberManagerData: {},
      subscriptionRenewData: {},
      assigningNumberData: {},
      getForwardDetails: {},
      selectOption: {},
      selectbatchData: "",
      deleteDeviceId: "",
      deleteFaxboxesId: "",
      deleteDevice: {},
      faxBoxList: [],
      deviceList: [],
      cellPhoneDeviceData: [],
      sipUriDeviceData: [],
      softPhoneDeviceData: [],
      getFaxboxesData: {},
      options: {},
      numberSubscriptionData: [],
      selectedNumberSubscriptionData: [],
      totalNumberSubscription: 0,
      didNumber: "",
      editedIndex: -1,
      getIndexNoData: "",
      numberSubscriptionList: [],
      forwardType: "",
      hasSms: "",
      showAssigningEmailDialog: false,
      assigningEmailData: {},
      ipAddress:"",
      inbound_format:"",
      username:"",
      actionsNOptions: [
        {
          title: "Order Details",
          value: "orderDetails",
        },
        {
          title: "Cancel number Termination request",
          value: "cancelNumberTermination",
        },
        { title: "Call Logs", value: "callLogs" },
      ],
      actionsYOptions: [
        {
          title: "Order Details",
          value: "orderDetails",
        },
        {
          title: "Remove DID",
          value: "removeDid",
        },
        { title: "Call Logs", value: "callLogs" },
      ],
      actionsInactiveOptions: [
        {
          title: "Order Details",
          value: "orderDetails",
        },
      ],
      actionsSuspendedOptions: [
        {
          title: "Order Details",
          value: "orderDetails",
        },
        {
          title: "Restore DID",
          value: "restoreDid",
        },
        { title: "Call Logs", value: "callLogs" },
      ],
      header: [
        {
          text: "",
          value: "singleSelect",
          sortable: false,
        },
        {
          text: "#",
          value: "indexNo",
          sortable: false,
        },
        {
          text: "Country / City",
          value: "country",
          align: "center",
          sortable: true,
        },
        {
          text: "DID Number ",
          value: "didNumber",
          align: "center",
          sortable: true,
        },
        {
          text: "Billing Tenure",
          value: "billingTenurePeriod",
          align: "center",
          sortable: true,
          width: "8%",
        },
        {
          text: "Status / Time left ",
          value: "daysRemaining",
          align: "center",
          sortable: true,
        },
        {
          text: "Order Id",
          value: "orderId",
          align: "center",
          sortable: true,
        },
        {
          text: "Did Status",
          value: "didStatus",
          align: "center",
          sortable: true,
        },
        {
          text: "SMS Enabled",
          value: "hasSms",
          align: "center",
          sortable: false,
        },
        {
          text: "Call Forwarding",
          value: "callForwarding",
          align: "center",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: true,
          width: "16%",
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getSubscriptionNew();
      },
      deep: true,
    },
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
  },
  methods: {
    openSmsDialog() {
      this.showAssigningEmailDialog = true;
    },
    closeDialog() {
      this.showAssigningEmailDialog = false;
    },
    async updateSettings() {
      if (this.$refs.smsForm.validate()) {
        this.isLoading = true;
        try {
          // Make API call or handle the update logic here
          let response = await numberManagerAPI.updatedEmailForSms({
            inventoryItemId: this.assigningEmailData.inventoryItemId,
            emailAddress: this.emailAddress,
            hasSmsForEmail: this.hasSmsForEmail,
          });
          this.isLoading = false;
          this.getSubscriptionNew();
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Your data has been successfully updated.",
            color: "success",
          });
          this.closeDialog();
        } catch (error) {
          this.isLoading = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong!",
            color: "error",
          });
          console.error("Update failed:", error);
        }
      }
    },
    async getSubscriptionNew() {
      this.numberSubscriptionData = [];
      this.isLoading = true;
      const { page, itemsPerPage } = this.options;

      try {
        let response = await numberManagerAPI.getDidNumberDetailSubscriptionNew(
          {
            viewIndex: page,
            viewSize: itemsPerPage,
            partyId: this.userDetail.partyId,
            didStatus: this.status,
          }
        );
        this.totalNumberSubscription = response.listSize;
        var numberSubscriptionDataArray = [];
        numberSubscriptionDataArray = response.numberList;
        var addFieldNumberSubscription = [];
        for (
          let index = 0;
          index < numberSubscriptionDataArray.length;
          index++
        ) {
          const element = numberSubscriptionDataArray[index];
          var checkNegtiveTime = Math.sign(
            element.daysRemaining.replace(/[^\d.-]/g, "")
          );
          if (checkNegtiveTime == "-1") {
            element.daysRemaining = "0  minutes";
          }
          element.singleSelect = "";
          element.disableSeleted = false;
          addFieldNumberSubscription.push(element);
          // get billing tenure value selected by user
          if (this.getIndexNoData === index) {
            this.checkBiillingTenurePeriod = element.billingTenurePeriod;
            this.confirmActionDialog = true;
            this.showdDeleteDeviceBtn = false;
            this.showSetBillingTenureBtn = true;
            this.showdFaxboxesBtn = false;
            this.numberSubscriptionData = this.numberSubscriptionList;
          }
        }
        if (this.getIndexNoData.length == 0) {
          this.numberSubscriptionData = addFieldNumberSubscription;
        }
        this.isLoading = false;
      } catch (error) {
        console.log("====error===", error);
        this.isLoading = false;
      }
    },
    openModal(item, indexNo) {
      this.getIndexNoData = "";
      this.numberSubscriptionList = [];
      this.selectedBilingTenureData = item;
      this.getIndexNoData = indexNo;
      this.numberSubscriptionList = this.numberSubscriptionData;
      this.getSubscriptionNew();
    },
    showSwitchServerModal() {
      this.isLoading = true;
      this.showAssigningNumberDialog = false;
      setTimeout(() => {
        this.showSwitchServerModalBox = true;
        this.isLoading = false;
      }, 1000);
    },
    async setBillingTenure() {
      if (
        this.checkBiillingTenurePeriod == null ||
        this.checkBiillingTenurePeriod == ""
      ) {
        var setBillingTenureData = {
          subscriptionId: this.selectedBilingTenureData.subscriptionId,
          billingTenurePeriod: this.selectedBilingTenureData.billingTenurePeriod.toString(),
          inventoryItemId: this.selectedBilingTenureData.inventoryItemId,
          orderId: this.selectedBilingTenureData.orderId,
          partyId: this.userDetail.partyId,
        };
        this.isLoading = true;
        try {
          let response = await numberManagerAPI.setBillingTenure(
            setBillingTenureData
          );
          this.isLoading = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.responseMessage,
            color: "success",
          });
          this.confirmActionDialog = false;
        } catch (error) {
          // console.log("====error===", error);
          this.isLoading = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong!",
            color: "error",
          });
        }
      } else {
        var updateBillingTenureData = {
          subscriptionId: this.selectedBilingTenureData.subscriptionId,
          billingTenurePeriod: this.selectedBilingTenureData.billingTenurePeriod.toString(),
          inventoryItemId: this.selectedBilingTenureData.inventoryItemId,
        };
        this.isLoading = true;
        try {
          let response = await numberManagerAPI.updateBillingTenure(
            updateBillingTenureData
          );
          this.isLoading = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.responseMessage,
            color: "success",
          });
          this.confirmActionDialog = false;
        } catch (error) {
          // console.log("====error===", error);
          this.isLoading = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong!",
            color: "error",
          });
        }
      }
    },
    async removeCurrentServer() {
      // console.log(
      //   "this.getForwardDetails.forwardType=========",
      //   this.forwardType
      // );
      if (this.forwardType == "SIP_URI") {
        var deleteCallFlowData = {
          accountId: this.userDetail.accountId,
          authToken: this.userDetail.authToken,
          inventoryItemId: this.assigningNumberData.inventoryItemId,
        };
        this.isLoading = true;
        try {
          let response = await numberManagerAPI.removeServerForward(
            deleteCallFlowData
          );
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.responseMessage,
            color: "success",
          });
          this.showSwitchServerModalBox = false;
          this.forwardType = "";
          setTimeout(() => {
            // this.openNonAssigningNumberDialog();
            this.openAssigningNumberDialog(this.assigningNumberData);
            this.isLoading = false;
          }, 1000);
        } catch (error) {
          // console.log("====error===", error);
          this.isLoading = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong!",
            color: "error",
          });
        }
      } else {
        var deleteCallFlowData = {
          accountId: this.userDetail.accountId,
          authToken: this.userDetail.authToken,
          didNumber: this.didNumber,
          inventoryItemId: this.assigningNumberData.inventoryItemId,
          callflowId: this.getForwardDetails.callflowId,
        };
        this.isLoading = true;
        try {
          let response = await numberManagerAPI.deleteCallFlow(
            deleteCallFlowData
          );
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.responseMessage,
            color: "success",
          });
          this.showSwitchServerModalBox = false;
          setTimeout(() => {
            // this.openNonAssigningNumberDialog();
            this.openAssigningNumberDialog(this.assigningNumberData);
            this.isLoading = false;
          }, 1000);
        } catch (error) {
          // console.log("====error===", error);
          this.isLoading = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong!",
            color: "error",
          });
        }
      }
    },
    async getDeviceItem(item) {
      this.deleteDeviceDialog = true;
      this.isLoading = true;
      this.getFaxboxesData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await numberManagerAPI.getDeviceList(
          this.getFaxboxesData
        );
        this.deviceList = response.deviceList;
        this.isLoading = false;
        this.getFaxboxes();
      } catch (error) {
        // console.log("====error===", error);
        this.isLoading = false;
      }
    },

    async getFaxboxes() {
      try {
        let responseFaxboxes = await numberManagerAPI.getFaxboxesList(
          this.getFaxboxesData
        );
        this.faxBoxList = responseFaxboxes.faxboxesList;
      } catch (error) {
        // console.log("====error===", error);
      }
    },
    confirmDeleteDeviceDialog(id) {
      this.deleteDeviceDialog = false;
      this.showdDeleteDeviceBtn = true;
      this.showSetBillingTenureBtn = false;
      this.showdFaxboxesBtn = false;
      this.deleteDeviceId = id;
      this.confirmActionDialog = true;
    },
    confirmFaxBoxListeDialog(id) {
      this.deleteDeviceDialog = false;
      this.showdDeleteDeviceBtn = false;
      this.showdFaxboxesBtn = true;
      this.showSetBillingTenureBtn = false;
      this.deleteFaxboxesId = id;
      this.confirmActionDialog = true;
    },
    closeConfirmActionDialog() {
      this.confirmActionDialog = false;
      this.deleteDevice = {};
      this.deviceList = [];
    },
    closeDeleteDeviceDialog() {
      this.deleteDeviceDialog = false;
      this.deleteDevice = {};
      this.deviceList = [];
    },
    closeAssignNumberModal() {
      this.showAssigningNumberDialog = false;
    },
    closeOnAssigned() {
      this.openAssigningNumberDialog(this.assigningNumberData);
    },
    closeRenewModal() {
      this.renewModalBox = false;
      this.getSubscriptionNew();
      this.$emit("getManagerList");
    },
    closeSwitchServerModal() {
      this.showSwitchServerModalBox = false;
      // setTimeout(() => {
      // this.selectOption = "Duckduckgo";
      // this.openNonAssigningNumberDialog();
      // this.openAssigningNumberDialog(this.assigningNumberData);
      // }, 1000);
    },
    async deleteDeviceDetails() {
      this.isLoading = true;
      var getFaxboxesData = {
        accountId: this.userDetail.accountId,
        deviceId: this.deleteDeviceId,
      };
      try {
        let response = await numberManagerAPI.deleteDeviceDetails(
          getFaxboxesData
        );
        this.deviceList = response.deviceList;
        this.isLoading = false;
        this.confirmActionDialog = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.responseMessage,
          color: "success",
        });
      } catch (error) {
        // console.log("====error===", error);
        this.isLoading = false;
        this.confirmActionDialog = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Something wrong!",
          color: "error",
        });
      }
    },
    async deleteFaxboxesDetails() {
      this.isLoading = true;
      var getFaxboxesData = {
        accountId: this.userDetail.accountId,
        faxboxesId: this.deleteFaxboxesId,
      };
      try {
        let response = await numberManagerAPI.deleteFaxboxesDetails(
          getFaxboxesData
        );
        this.deviceList = response.deviceList;
        this.isLoading = false;
        this.confirmActionDialog = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.responseMessage,
          color: "success",
        });
      } catch (error) {
        // console.log("====error===", error);
        this.isLoading = false;
        this.confirmActionDialog = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Something wrong!",
          color: "error",
        });
      }
    },
    openSubscriptionRenewDialog(item) {
      this.selectNumberManagerData = item;
      this.showSubscriptionRenewDialog = true;
      this.getDidRenewTotalCost();
    },
    openRenewTotalCostDialog(item) {
      this.selectNumberManagerData = item;
      this.renewModalBox = true;
      // this.$emit("getManagerList");
    },
    async getDidRenewTotalCost() {
      this.isLoading = true;
      var getDIDRenewData = {
        inventoryItemId: this.selectNumberManagerData.inventoryItemId,
        orderId: this.selectNumberManagerData.orderId,
      };
      try {
        let response = await numberManagerAPI.getDIDRenewTotalCost(
          getDIDRenewData
        );
        this.subscriptionRenewData = response.outMap;
        this.isLoading = false;
      } catch (error) {
        // console.log("====error===", error);
        this.isLoading = false;
      }
    },
    openshowRenewModal() {
      this.confirmActionDialog = true;
      this.showRenew = true;
      this.showSubscriptionRenewDialog = false;
    },
    async subscriptionRenew() {
      this.isLoading = true;
      try {
        let response = await numberManagerAPI.runSubscriptionExpired({
          subscriptionId: this.selectNumberManagerData.subscriptionId,
        });
        this.isLoading = false;
        this.confirmActionDialog = false;
        this.$router.go(this.$router.currentRoute);
      } catch (error) {
        // console.log("====error===", error);
        this.isLoading = false;
        this.confirmActionDialog = false;
      }
    },
    async openAssigningNumberDialog(item) {
      this.isLoading = true;
      this.getFaxboxesData = {
        accountId: this.userDetail.accountId,
      };
      var ForwardDetailsData = {
        accountId: this.userDetail.accountId,
        inventoryItemId: item.inventoryItemId,
      };
      try {
        let response = await numberManagerAPI.getForwardDetails(
          ForwardDetailsData
        );
        this.forwardType = response.forwardDetails.forwardType;
        this.getForwardDetails = response.forwardDetails;
        if (this.getForwardDetails.forwardType != "NONE") {
          this.selectOption = this.getForwardDetails;

           // Check if forwardType is "SIP_URI" and handle accordingly
          if (this.getForwardDetails.forwardType === "SIP_URI") {
            this.matchingServers = this.getForwardDetails.matchingServer; // Use getForwardDetails instead of forwardDetails
            // Ensure matchingServer is neither null nor undefined
            if (this.matchingServers !== null && this.matchingServers !== undefined) {
              this.inbound_format = this.matchingServers.options.inbound_format;
               if (this.matchingServers.auth.ip !== null) {
                  const fullValue = this.matchingServers.auth.ip; // Example: "abc@12.13.12.13"
                  this.username = fullValue.split('@')[0]; // Get the part before '@'
                  // Extract the part after '@' (IP address), if it exists
                  this.ipAddress = fullValue.split('@')[1];
                  // If there's no '@' and hence no IP address part, use the entire value
                  if (!this.ipAddress) {
                    this.ipAddress = fullValue;
                  }
               }
                console.log(this.username+" :username---ipAddress---"+this.ipAddress); 
            }else{
              this.ipAddress = this.getForwardDetails.forwardTo;
              this.inbound_format = "e164";
              this.username = "";
            }
          }
        } else if (this.getForwardDetails.forwardType == "NONE") {
          this.selectOption = "Duckduckgo";
        }
        this.assigningNumberData = item;
        this.didNumber = item.didNumber;
        this.showAssigningNumberDialog = true;
        // this.isLoading = false;
        this.getFaxboxes();
        this.getCellPhoneDeviceList();
      } catch (error) {
        // console.log("====error==="),
        this.isLoading = false;
        // this.isLoading = false;
      }
    },
    openNonAssigningNumberDialog() {
      this.getForwardDetails.forwardType = "NONE";
      if (this.getForwardDetails.forwardType == "NONE") {
        this.selectOption = "Duckduckgo";
        this.showAssigningNumberDialog = true;
      }
    },
    async getCellPhoneDeviceList() {
      var getFilteredDeviceData = {
        accountId: this.userDetail.accountId,
        forwardType: "CELLPHONE",
      };
      try {
        let response = await numberManagerAPI.getFilteredDeviceList(
          getFilteredDeviceData
        );
        this.cellPhoneDeviceData = response.deviceList;
        // this.isLoading = false;
        this.getSipUriDeviceList();
      } catch (error) {
        // console.log("====error===", error);
        // this.isLoading = false;
      }
    },
    async getSipUriDeviceList() {
      var getFilteredDeviceData = {
        accountId: this.userDetail.accountId,
        forwardType: "SIP_URI",
      };
      try {
        let response = await numberManagerAPI.getFilteredDeviceList(
          getFilteredDeviceData
        );
        this.sipUriDeviceData = response.serverList;
        console.log("===========sipUriDeviceData========="+this.sipUriDeviceData);
        this.getSoftPhoneDeviceList();
      } catch (error) {
        // console.log("====error===", error);
        // this.isLoading = false;
      }
    },
    async getSoftPhoneDeviceList() {
      var getFilteredDeviceData = {
        accountId: this.userDetail.accountId,
        forwardType: "SOFTPHONE",
      };
      try {
        let response = await numberManagerAPI.getFilteredDeviceList(
          getFilteredDeviceData
        );
        this.softPhoneDeviceData = response.deviceList;
        this.isLoading = false;
      } catch (error) {
        // console.log("====error===", error);
        // this.isLoading = false;
      }
    },
    async selectBatch(item, selectedOption) {
      this.particularAssigningNumberData = [];
      if (selectedOption == "orderDetails") {
        this.$router.push("/order/" + item.orderId);
      } else if (selectedOption == "removeDid") {
        this.showRemoveDid = true;
        this.particularAssigningNumberData.push(item);
      } else if (selectedOption == "callLogs") {
        this.$router.push("/cdr");
      } else if (selectedOption == "cancelNumberTermination") {
        var terminateNumberData = {
          subscriptionId: item.subscriptionId,
          autoRenew: "Y",
        };
        this.isLoading = true;
        try {
          let response = await numberManagerAPI.autoRenewSubscription(
            terminateNumberData
          );
          this.isLoading = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.responseMessage,
            color: "success",
          });
          this.getSubscriptionNew();
        } catch (error) {
          // console.log("====error===", error);
          this.isLoading = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong!",
            color: "error",
          });
          this.getSubscriptionNew();
        }
      } else if ((item.value = "restoreDid")) {
        this.openRenewTotalCostDialog(item);
      }
    },
    closeRemoveDidDialog() {
      this.showRemoveDid = false;
      this.getSubscriptionNew();
    },
    getSelectData(selectedItem) {
      var selectedItemObj = selectedItem;
      this.editedIndex = this.selectedNumberSubscriptionData.indexOf(
        selectedItemObj
      );
      if (selectedItem.singleSelect === true) {
        this.selectedNumberSubscriptionData.push(selectedItemObj);
      } else if (selectedItem.singleSelect === false) {
        this.selectedNumberSubscriptionData.splice(this.editedIndex, 1);
      }
      if (this.selectedNumberSubscriptionData.length >= 10) {
        var getDisableData = [];

        for (
          let index = 0;
          index < this.numberSubscriptionData.length;
          index++
        ) {
          const element = this.numberSubscriptionData[index];
          if (element.singleSelect === true) {
            element.disableSeleted = false;
          } else if (element.singleSelect === "") {
            element.disableSeleted = true;
          }
          getDisableData.push(element);
        }
        this.numberSubscriptionData = getDisableData;
      } else if (this.selectedNumberSubscriptionData.length <= 9) {
        var getDisableData = [];

        for (
          let index = 0;
          index < this.numberSubscriptionData.length;
          index++
        ) {
          const element = this.numberSubscriptionData[index];
          if (element.singleSelect === true) {
            element.disableSeleted = false;
          } else if (element.singleSelect === false) {
            element.disableSeleted = false;
          } else if (element.singleSelect === "") {
            element.disableSeleted = false;
          }
          getDisableData.push(element);
        }
        this.numberSubscriptionData = getDisableData;
      }
    },
    openRemoveDidModal() {
      this.particularAssigningNumberData = [];
      this.showRemoveDid = true;
      this.particularAssigningNumberData = this.selectedNumberSubscriptionData;
    },
    async openAssigningEmailDialog(item) {
      console.log("item", item);
      this.assigningEmailData = item;
      this.emailAddress = item?.emailAddress || "";
      this.hasSmsForEmail = item?.hasSmsForEmail || "Y";
      this.showAssigningEmailDialog = true;
      console.log("showAssigningEmailDialog: " + this.showAssigningEmailDialog);
    },
  },
};
</script>
