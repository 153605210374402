<template>
  <v-card>
    <v-toolbar elevation="0"
      ><v-card-title class="px-0"> Assigning Number </v-card-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeAssignNumberModal">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <!-- Assigning Numbers Options -->
    <v-card-text class="pb-0">
      <p class="pt-2 pb-0">
        <b>Choose one of the following forward Options:</b>
      </p>
      <v-radio-group
        v-model="selectOption"
        @change="getSelectOption(selectOption)"
        class="pt-0 pb-2"
      >
        <v-radio value="CELL_PHONE">
          <template v-slot:label>
            <div class="black--text">Regular phone</div>
          </template>
        </v-radio>
        <v-radio value="Duckduckgo">
          <template v-slot:label>
            <div class="black--text">
              DIDnPBX <small> (Our Free Fully Hosted PBX)</small>
            </div>
          </template>
        </v-radio>
        <v-radio value="Softswitch">
          <template v-slot:label>
            <div class="black--text">Forward to Your Own SIP Server</div>
          </template>
        </v-radio>
        <v-radio value="SIPSETTING">
          <template v-slot:label>
            <div class="black--text">SIPSETTING</div>
          </template>
        </v-radio>
        <v-radio value="Fax2Email">
          <template v-slot:label>
            <div class="black--text">Fax2Email</div>
          </template>
        </v-radio>
      </v-radio-group>

      <v-divider></v-divider>

      <!-- Assigning Regular Numbers type Options -->
      <v-radio-group
        v-show="showRegularPhone"
        v-model="selectAssigningTypeOption"
        @change="getSelectAssigningType"
      >
        <v-radio value="new">
          <template v-slot:label>
            <div class="black--text">New</div>
          </template>
        </v-radio>
        <v-radio value="existing">
          <template v-slot:label>
            <div class="black--text">Existing</div>
          </template>
        </v-radio>
      </v-radio-group>
      <span v-show="showRegularPhoneInput">
        <v-text-field
          v-model="cellPhoneNumber.deviceName"
          label="Enter device name"
          append-icon="call"
          outlined
          rounded
          dense
        ></v-text-field>
        <v-text-field
          v-model="cellPhoneNumber.cellPhone"
          label="Enter Complete number with country code"
          type="number"
          append-icon="call"
          outlined
          rounded
          dense
        ></v-text-field>
        <v-divider></v-divider>
        <v-card-actions class="py-3">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="px-4"
            outlined
            @click="closeAssignNumberModal"
          >
            Cancel
          </v-btn>
          <v-btn class="px-4 ml-4 primary-btn" outlined @click="assignNumber">
            Assign
          </v-btn>
        </v-card-actions>
      </span>
    </v-card-text>

    <!-- Regular phone  -->
    <v-card-text class="pb-0" v-show="showRegularPhoneSelectList">
      <v-select
        v-if="!showParticularDeviceDetail"
        :items="cellPhoneDeviceData"
        v-model="cellPhoneNumber.id"
        item-text="name"
        item-value="id"
        label="Select Device"
        append-icon="call"
        outlined
        rounded
        dense
      >
      </v-select>
      <span v-show="showParticularDeviceDetail">
        <v-text-field
          v-model="cellPhoneNumber.deviceName"
          label="Enter device name"
          append-icon="call"
          outlined
          rounded
          dense
        ></v-text-field>
        <v-text-field
          v-model="cellPhoneNumber.cellPhone"
          label="Enter Complete number with country code"
          type="number"
          append-icon="call"
          outlined
          rounded
          dense
        ></v-text-field>
      </span>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          class="px-4"
          outlined
          @click="closeAssignNumberModal"
          dark
        >
          Cancel
        </v-btn>
        <v-btn class="px-4 ml-4 primary-btn" outlined @click="assignNumber">
          Assign
        </v-btn>
      </v-card-actions>
    </v-card-text>

    <!-- Our Own Softswitch-->
    <v-card-text v-show="showSoftswitchRadio">
      <v-divider></v-divider>
      <createSoftSwitch
        :assigningNumberData="assigningNumberData"
        :sipUriDeviceData="sipUriDeviceData"
        @close-asign-modal="closeModal"
        @closedAssignedModal="closedAssignedModal"
        ref="closeSoftSwitch"
      />
    </v-card-text>

    <!-- Sip Settting Option-->
    <v-card-text class="pb-0" v-show="showSipSettingInput">
      <v-divider></v-divider>
      <sipSettingVue
        :assigningNumberData="assigningNumberData"
        :softPhoneDeviceData="softPhoneDeviceData"
        @close-asign-modal="closeModal"
        @closedAssignedModal="closedAssignedModal"
      />
    </v-card-text>

    <!-- Assigning Fax2 Email -->
    <v-card-text class="pt-0 pb-0" v-show="showFaxEmail">
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" lg="12">
          <v-radio-group
            v-model="selectFaxEmailOption"
            @change="getFaxEmailType()"
          >
            <v-radio value="new">
              <template v-slot:label>
                <div class="black--text">New</div>
              </template>
            </v-radio>
            <v-radio value="existing">
              <template v-slot:label>
                <div class="black--text">Existing</div>
              </template>
            </v-radio>
          </v-radio-group>
          <span v-show="showFaxEmailInput">
            <v-text-field
              v-model="faxEmail"
              label="email"
              outlined
              rounded
              dense
              append-icon="email"
            ></v-text-field>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                class="px-4"
                outlined
                dark
                @click="closeAssignNumberModal"
              >
                Cancel
              </v-btn>
              <v-btn
                outlined
                class="px-4 ml-4 primary-btn"
                dark
                @click="assignFaxEmail"
              >
                Assign
              </v-btn>
            </v-card-actions>
          </span>
          <span v-show="showFaxEmailSelectList">
            <v-select
              :items="faxBoxList"
              item-text="name"
              item-value="name"
              v-model="faxEmail"
              label="Select Email"
              append-icon="call"
              outlined
              rounded
              dense
            >
              <template slot="item" slot-scope="data">
                <v-list-item-content @click="getItemText(data.item)">
                  <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                class="px-4"
                outlined
                @click="closeAssignNumberModal"
                dark
              >
                Cancel
              </v-btn>
              <v-btn
                class="px-4 ml-4 primary-btn"
                outlined
                @click="assignFaxEmail"
              >
                Assign
              </v-btn>
            </v-card-actions>
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- loader -->
    <loading :active="isLoading" :loader="loader" />
  </v-card>
</template>

<script>
import numberManagerAPI from "@/services/numberManagerAPI.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import createSoftSwitch from "./common/createSoftSwitch.vue";
import sipSettingVue from "./common/sipSetting.vue";

export default {
  components: {
    Loading,
    createSoftSwitch,
    sipSettingVue,
  },
  props: [
    "assigningNumberData",
    "cellPhoneDeviceData",
    "sipUriDeviceData",
    "softPhoneDeviceData",
    "faxBoxList",
    "getForwardDetails",
  ],
  data() {
    return {
      getSelectedOption: "",
      hideBtn: false,
      selectAssigningTypeOption: "new",
      showRegularPhone: false,
      showRegularPhoneInput: false,
      cellPhoneNumber: {},
      getPhoneDevideData: "",
      userDetail: {},
      showRegularPhoneSelectList: false,
      showSipSettingInput: false,
      showFaxEmail: false,
      showSipSettingPhoneInput: false,
      showFaxEmailInput: false,
      showFaxEmailSelectList: false,
      showSoftswitchRadio: false,
      selectFaxEmailOption: "new",
      faxToMail: "",
      faxEmail: "",
      selectOption: "Duckduckgo",
      isLoading: false,
      loader: "bars",
      deviceDetail: {},
      showParticularDeviceDetail: false,
      selectedDeviceName: "",
      selectedDeviceId: "",
      deviceName: "",
      callerId: "",
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
  },
  methods: {
    getItemText(selectText) {
      // get the selected module name
      this.selectedDeviceName = selectText.name;
      this.selectedDeviceId = selectText.id;
    },
    getSelectOption() {
      // if recieved callflowId
      if (
        this.getForwardDetails &&
        this.getForwardDetails.forwardType == "NONE"
      ) {
        if (this.getForwardDetails.callflowId != null) {
          this.$emit("switchServerModal");
        }
      }
      // if not recieved callflowId
      if (this.selectOption == "CELL_PHONE") {
        this.showRegularPhone = true;
        this.showRegularPhoneInput = true;
        this.showFaxEmail = false;
        this.showSipSettingInput = false;
        this.showSipSettingPhoneInput = false;
        this.showSipSettingSelectList = false;
        this.showFaxEmailSelectList = false;
        this.showFaxEmailInput = false;
        // //this.showSoftswitch = false;
        this.showSoftswitchRadio = false;
      } else if (this.selectOption == "Fax2Email") {
        this.showFaxEmail = true;
        this.showRegularPhone = false;
        this.showRegularPhoneInput = false;
        this.showRegularPhoneSelectList = false;
        this.showSipSettingInput = false;
        this.showSipSettingPhoneInput = false;
        this.showFaxEmailSelectList = false;
        this.showFaxEmailInput = true;
        this.showSoftswitchRadio = false;
      } else if (this.selectOption == "SIPSETTING") {
        this.showSipSettingPhoneInput = true;
        this.showRegularPhone = false;
        this.showFaxEmail = false;
        this.showRegularPhoneInput = false;
        this.showRegularPhoneSelectList = false;
        this.showSipSettingInput = true;
        this.showFaxEmailSelectList = false;
        this.showFaxEmailInput = false;
        this.showSoftswitchRadio = false;
      } else if (this.selectOption == "Softswitch") {
        this.showSoftswitchRadio = true;
        this.showSipSettingPhoneInput = false;
        this.showRegularPhone = false;
        this.showFaxEmail = false;
        this.showRegularPhoneInput = false;
        this.showRegularPhoneSelectList = false;
        this.showSipSettingInput = false;
        this.showFaxEmailSelectList = false;
        this.showFaxEmailInput = false;
      } else if (this.selectOption == "Duckduckgo") {
        this.showSoftswitchRadio = false;
        this.showSipSettingPhoneInput = false;
        this.showRegularPhone = false;
        this.showFaxEmail = false;
        this.showRegularPhoneInput = false;
        this.showRegularPhoneSelectList = false;
        this.showSipSettingInput = false;
        this.showFaxEmailSelectList = false;
        this.showFaxEmailInput = false;
      }
    },
    getSelectAssigningType() {
      if (this.selectAssigningTypeOption == "new") {
        this.showRegularPhoneInput = true;
        this.showRegularPhoneSelectList = false;
        this.cellPhoneNumber = {};
      }
      if (this.selectAssigningTypeOption == "existing") {
        this.showRegularPhoneSelectList = true;
        this.showRegularPhoneInput = false;
      }
    },
    getFaxEmailType() {
      // this.showFaxEmail = true;
      if (this.selectFaxEmailOption == "new") {
        this.showFaxEmailInput = true;
        this.showFaxEmailSelectList = false;
      }
      if (this.selectFaxEmailOption == "existing") {
        this.showFaxEmailInput = false;
        this.showFaxEmailSelectList = true;
      }
    },
   
    newSipSetting() {
      this.showSipSettingInput = true;
    },
   
    closeAssignNumberModal() {
      this.showSoftswitchRadio = false;
      this.$emit("closeModal");
      this.getSelectOption();
    },
    closeShowSoftswitchModal() {
      this.$refs.closeSoftSwitch.setValue();
      this.showSoftswitchRadio = false;
      this.$emit("closeModal");
    },
    closeModal() {
      this.showSoftswitchRadio = false;
      this.showSipSettingInput = false;
    },
    async assignNumber() {
      this.isLoading = true;
      var asignNumberData = {
        accountId: this.userDetail.accountId,
        authToken: this.userDetail.authToken,
        callflowId: this.userDetail.callflowId,
        deviceName: this.cellPhoneNumber.deviceName,
        cellPhone: this.cellPhoneNumber.cellPhone,
        inventoryItemId: this.assigningNumberData.inventoryItemId,
        didNumber: this.assigningNumberData.didNumber,
      };
      if (this.cellPhoneNumber.id) {
        asignNumberData.deviceId = this.cellPhoneNumber.id;
      }
      try {
        let response = await numberManagerAPI.assignCellPhone(asignNumberData);
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.responseMessage,
          color: "success",
        });
        this.$emit("closedAssignedModal");
        this.isLoading = false;
      } catch (error) {
        // console.log("====error===", error);
        this.isLoading = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong!",
          color: "error",
        });
      }
    },
    async assignFaxEmail() {
      this.isLoading = true;
      var assignFaxBox2EmailObj = {
        accountId: this.userDetail.accountId,
        authToken: this.userDetail.authToken,
        callflowId: this.userDetail.callflowId,
        inventoryItemId: this.assigningNumberData.inventoryItemId,
        didNumber: this.assigningNumberData.didNumber,
        email: this.faxEmail,
      };
      if (this.selectedDeviceId) {
        assignFaxBox2EmailObj.deviceId = this.selectedDeviceId;
      }
      if (this.cellPhoneNumber.id) {
        assignFaxBox2EmailObj.deviceId = this.cellPhoneNumber.id;
      }
      try {
        let response = await numberManagerAPI.assignFaxBox2Email(
          assignFaxBox2EmailObj
        );
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.responseMessage,
          color: "success",
        });
        this.$emit("closedAssignedModal");

        this.isLoading = false;
      } catch (error) {
        // console.log("====error===", error);
        this.isLoading = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong!",
          color: "error",
        });
      }
    },
    closedAssignedModal() {
      this.$emit("closedAssignedModal");
    },
  },
};
</script>