<template>
  <section>
    <v-card elevation="1" :loading="cardLoader">
      <v-card-title class="black--text pl-2"
        >Subscription Renew Details
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-list subheader two-line>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Suspended Period Rental</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-title
                v-text="didRenewData.expireDayCost"
              ></v-list-item-title>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Renew Charge </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-title
                v-text="didRenewData.unitPrice"
              ></v-list-item-title>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Total Cost</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-title
                v-text="didRenewData.totalPrice"
              ></v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn outlined color="error" class="px-4" dark @click="closeDialog()">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn outlined class="px-4 primary-btn" dark @click="confirmDelete()">
          Renew
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- action modal -->
    <v-dialog v-model="openModal" width="400">
      <v-card>
        <v-card-title class="title">
          <span>Are you sure,you want to Renew?</span>
          <br /><br />
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="openModal = false">
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            outlined
            :loading="isLoading"
            @click="renew()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>


<script>
import numberManagerAPI from "@/services/numberManagerAPI.js";

export default {
  props: ["selectNumberManagerData"],
  data() {
    return {
      isLoading: false,
      didRenewData: {},
      cardLoader: false,
      openModal: false,
    };
  },
  mounted() {
    this.getDIDRenewTotalCost();
  },
  methods: {
    async getDIDRenewTotalCost() {
      this.cardLoader = true;
      var didRenew = {
        inventoryItemId: this.selectNumberManagerData.inventoryItemId,
        orderId: this.selectNumberManagerData.orderId,
      };
      try {
        let response = await numberManagerAPI.getDIDRenewTotalCost(didRenew);
        this.didRenewData = response.outMap;
        this.cardLoader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.responseMessage,
          color: "success",
        });
      } catch (error) {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong!",
          color: "error",
        });
      }
    },
    confirmDelete() {
      this.openModal = true;
    },
    closeDialog() {
      this.$emit("closeRenewModalBox");
    },
    async renew() {
      this.isLoading = true;
      var runSubscriptionData = {
        subscriptionId: this.selectNumberManagerData.subscriptionId,
      };
      try {
        let response = await numberManagerAPI.runSubscriptionExpired(
          runSubscriptionData
        );
        this.openModal = false;
        this.isLoading = false;
        this.closeDialog();
        this.$emit("SHOW_SNACKBAR", {
          text: response.responseMessage,
          color: "success",
        });
      } catch (error) {
        this.isLoading = false;
        if (error && error.data && error.data.messageDetail) {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: error.data.messageDetail,
            color: "error",
          });
        } else {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong!",
            color: "error",
          });
        }
      }
    },
  },
};
</script>