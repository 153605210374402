<template>
  <v-card elevation="1">
    <v-card-text class="px-3">
      <v-card-title class="black--text px-0"
        >Are you sure you want to remove slected services(s)?
      </v-card-title>
      <v-divider></v-divider>
      <!-- alert -->
      <v-alert
        border="left"
        type="warning"
        color="yellow darken-3"
        dark
        class="mt-4"
      >
        <span>IMPORTANT - The service(s) will be cancelled immediately.</span>
        <br />
        <span>No refund will be issued for the service time remaining.</span>
      </v-alert>
      <!--number manager perticular data -->
      <v-card class="elevation-1" dense>
        <v-data-table
          :items="particularAssigningNumber"
          :headers="headers"
          hide-default-footer
        >
          <template v-slot:[`item.thruDate`]="{ item }">
            {{ moment(item.thruDate).format("MMMM Do YYYY") }}
          </template>
        </v-data-table>
      </v-card>

      <!-- Assigning Numbers Options -->
      <v-radio-group v-model="selectOption" class="mt-4 py-3">
        <v-radio value="Immediate">
          <template v-slot:label>
            <div class="black--text">Immediate service removal</div>
          </template>
        </v-radio>
        <v-radio value="Duckduckgo">
          <template v-slot:label>
            <div class="black--text">Delayed Service removal</div>
          </template>
        </v-radio>
      </v-radio-group>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="px-4">
      <v-spacer></v-spacer>
      <v-btn
        color="error lighten-2"
        outlined
        class="px-4"
        dark
        @click="closeDialog"
      >
        Close
      </v-btn>
      <v-btn
        class="px-4 ml-4 primary-btn"
        :loading="isLoading"
        outlined
        dark
        @click="submit"
        v-if="particularAssigningNumber.length === 1"
      >
        Submit
      </v-btn>
      <v-btn
        class="px-4 ml-4 primary-btn"
        :loading="isLoading"
        outlined
        dark
        @click="submitMultipleDIDData"
        v-if="particularAssigningNumber.length != 1"
      >
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import numberManagerAPI from "@/services/numberManagerAPI.js";
import moment from "moment";

export default {
  props: ["particularAssigningNumber"],
  data() {
    return {
      moment: moment,
      userDetail: {
        showAssigningNumberDialog: false,
      },
      selectOption: "Immediate",
      isLoading: false,
      headers: [
        {
          text: "Country / City",
          value: "country",
          align: "center",
          sortable: true,
        },
        {
          text: "DID Number ",
          value: "didNumber",
          align: "center",
          sortable: true,
        },
        {
          text: "Order Id",
          value: "orderId",
          align: "center",
          sortable: true,
        },
        {
          text: "Expire At",
          value: "thruDate",
          align: "center",
          sortable: true,
        },
      ],
      authToken: "",
      timeStampDate: "",
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.authToken = localStorage.getItem("authToken");
    this.addDays(15);
  },
  methods: {
    addDays(n) {
      var t = new Date();
      t.setDate(t.getDate() + n);
      var month = "0" + (t.getMonth() + 1);
      var date = "0" + t.getDate();
      month = month.slice(-2);
      date = date.slice(-2);
      var date = date + "/" + month + "/" + t.getFullYear();
      // console.log(date);
      var myDate = date.split("/");
      // console.log(myDate);
      var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
      // console.log(newDate.getTime());
      this.timeStampDate = newDate.getTime();
      // console.log("this.timeStampDate", this.timeStampDate);
    },

    async submit() {
      // console.log("this.selectOption", this.selectOption);
      if (this.selectOption === "Immediate") {
        this.submitTerminateDid();
      } else if (this.selectOption === "Duckduckgo") {
        this.submitTerminateDelay();
      }
    },
    async submitMultipleDIDData() {
      if (this.selectOption === "Immediate") {
        this.submitTerminatesImmediate();
      } else if (this.selectOption === "Duckduckgo") {
        this.submitTerminatesNumbersDelayed();
      }
    },
    async submitTerminateDid() {
      var terminateDIDData = {
        accountId: this.userDetail.accountId,
        inventoryItemId: this.particularAssigningNumber[0].inventoryItemId,
      };
      if (this.particularAssigningNumber[0].callflowId) {
        terminateDIDData.callflowId =
          this.particularAssigningNumber[0].callflowId;
      }
      this.isLoading = true;
      try {
        let response = await numberManagerAPI.terminateDID(terminateDIDData);
        this.isLoading = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.responseMessage,
          color: "success",
        });
        this.closeDialog();
      } catch (error) {
        // console.log("====error===", error);
        this.isLoading = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong!",
          color: "error",
        });
        this.closeDialog();
      }
    },
    async submitTerminateDelay() {
      var terminateNumberDelayedData = {
        subscriptionId: this.particularAssigningNumber[0].subscriptionId,
        partyId: this.userDetail.partyId,
        didNumber: this.particularAssigningNumber[0].didNumber,
      };
      // console.log("terminateNumberDelayedData", terminateNumberDelayedData);
      this.isLoading = true;
      try {
        let response = await numberManagerAPI.terminateNumberDelayed(
          terminateNumberDelayedData
        );
        this.isLoading = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.responseMessage,
          color: "success",
        });
        this.closeDialog();
      } catch (error) {
        // console.log("====error===", error);
        this.isLoading = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong!",
          color: "error",
        });
        this.closeDialog();
      }
    },

    async submitTerminatesImmediate() {
      var getTerminatesImmediateObjData = {};
      var assigningNumberArray = [];
      for (
        let index = 0;
        index < this.particularAssigningNumber.length;
        index++
      ) {
        const element = this.particularAssigningNumber[index];
        if (element.callflowId != null) {
          getTerminatesImmediateObjData.callflowId = element.callflowId;
        }
        getTerminatesImmediateObjData.inventoryItemId = element.inventoryItemId;
        assigningNumberArray.push(getTerminatesImmediateObjData);
      }
      var terminateNumbersImmediateData = {
        accountId: this.userDetail.accountId,
        numbers: assigningNumberArray,
      };
      this.isLoading = true;
      try {
        let response = await numberManagerAPI.terminateNumbersImmediate(
          terminateNumbersImmediateData
        );
        this.isLoading = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.responseMessage,
          color: "success",
        });
        this.closeDialog();
      } catch (error) {
        // console.log("====error===", error);
        this.isLoading = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong!",
          color: "error",
        });
        this.closeDialog();
      }
    },
    async submitTerminatesNumbersDelayed() {
      var getTerminatesDelayedData = {};
      var terminateDelayedDataArray = [];
      for (
        let index = 0;
        index < this.particularAssigningNumber.length;
        index++
      ) {
        const element = this.particularAssigningNumber[index];
        getTerminatesDelayedData = {
          subscriptionId: element.subscriptionId,
          didNumber: element.didNumber,
        };
        terminateDelayedDataArray.push(getTerminatesDelayedData);
      }

      var terminateNumbersDelayedData = {
        partyId: this.userDetail.partyId,
        numbers: terminateDelayedDataArray,
      };
      // console.log("terminateNumberDelayedData", terminateNumberDelayedData);
      this.isLoading = true;
      try {
        let response = await numberManagerAPI.terminateNumbersDelayed(
          terminateNumbersDelayedData
        );
        this.isLoading = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: response.responseMessage,
          color: "success",
        });
        this.closeDialog();
      } catch (error) {
        // console.log("====error===", error);
        this.isLoading = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong!",
          color: "error",
        });
        this.closeDialog();
      }
    },

    closeDialog() {
      this.$emit("close-remove-did-dialog");
    },
    // terminateDID() {

    // }
  },
};
</script>