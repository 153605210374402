<template>
  <section>
    <v-select
      :items="softPhoneDeviceData"
      item-text="name"
      item-value="id"
      v-model="getPhoneDevideData"
      label="Device List"
      append-icon="call"
      outlined
      rounded
      dense
    >
      <template slot="item" slot-scope="data">
        <v-list-item-content @click="getItemText(data.item.name)">
          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-select>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        class="px-4"
        outlined
        @click="closeModal"
        dark
      >
        Cancel
      </v-btn>
      <v-btn
        class="px-4 ml-4 primary-btn"
        :loading="isLoading"
        outlined
        @click="assignSipSetting"
      >
        Assign
      </v-btn>
    </v-card-actions>
  </section>
</template>

<script>
import numberManagerAPI from "@/services/numberManagerAPI.js";

export default {
  name: "exitSoftPhoneList",
  props: ["softPhoneDeviceData", "userDetail", "assigningNumberData"],
  data() {
    return {
      getPhoneDevideData: "",
      selectedDeviceName: "",
      isLoading: false,
    };
  },
  methods: {
    getItemText(selectText) {
      // get the selected module name
      this.selectedDeviceName = selectText;
    },
    closeAssignNumberModal() {
      this.$emit("closedAssignedModal");
    },
    closeModal() {
      this.$emit("closeModal");
    },
    async assignSipSetting() {
      if (this.getPhoneDevideData) {
        this.isLoading = true;
        var didToServerObj = {
          accountId: this.userDetail.accountId,
          authToken: this.userDetail.authToken,
          callflowId: this.userDetail.callflowId,
          inventoryItemId: this.assigningNumberData.inventoryItemId,
          didNumber: this.assigningNumberData.didNumber,
          sipName: this.selectedDeviceName,
          deviceId: this.getPhoneDevideData,
        };
        try {
          let response = await numberManagerAPI.assignSip(didToServerObj);
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.responseMessage,
            color: "success",
          });
          this.$emit("closedAssignedModal");
          this.isLoading = false;
        } catch (error) {
          // console.log("====error===", error);
          this.isLoading = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong!",
            color: "error",
          });
        }
      } else {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Select Device!",
          color: "error",
        });
      }
    },
  },
};
</script>