<template>
  <v-row>
    <v-col cols="12" lg="12">
      <v-radio-group
        v-model="selectSipSettingInput"
        @change="getSelectSipSettingType"
      >
        <v-radio value="new">
          <template v-slot:label>
            <div class="black--text">New</div>
          </template>
        </v-radio>
        <v-radio value="existing">
          <template v-slot:label>
            <div class="black--text">Existing</div>
          </template>
        </v-radio>
      </v-radio-group>
      <span v-show="showSipSettingPhoneInput">
        <v-text-field
          v-model="getSelectSipSettingData.deviceName"
          label="Device Nickname"
          append-icon="person"
          outlined
          rounded
          dense
        ></v-text-field>
        <v-select
          label="Caller id number"
          :items="subscriptionNewList"
          item-text="didNumber"
          item-value="didNumber"
          v-model="getSelectSipSettingData.callerId"
          outlined
          color="primary"
          clearable
          rounded
          dense
        >
        </v-select>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="px-4"
            dark
            @click="closeAssignNumberModal"
            outlined
          >
            Cancel
          </v-btn>
          <v-btn
            class="px-4 ml-4 primary-btn"
            outlined
            :loading="isLoading"
            @click="assignSipSetting"
          >
            Assign
          </v-btn>
        </v-card-actions>
      </span>
      <!-- existing devices -->
      <section class="py-0" v-show="showSipSettingSelectList">
        <exist-soft-phone-list
          :assigningNumberData="assigningNumberData"
          :userDetail="userDetail"
          :softPhoneDeviceData="softPhoneDeviceData"
          @closeModal="closeAssignNumberModal"
          @closedAssignedModal="closedAssignedModal"
        />
      </section>
    </v-col>
  </v-row>
</template>

<script>
import numberManagerAPI from "@/services/numberManagerAPI.js";
import ExistSoftPhoneList from "./existSoftPhoneList.vue";

export default {
  props: ["softPhoneDeviceData", "assigningNumberData"],
  components: {
    ExistSoftPhoneList,
  },
  data() {
    return {
      selectSipSettingInput: "new",
      isLoading: false,
      getSelectSipSettingData: {},
      showSoftswitchInput: false,
      isFormValid: false,
      deviceName: "",
      userDetail: {},
      showSipSettingSelectList: false,
      showSipSettingPhoneInput: true,
      subscriptionNewList: [],
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.getSubscriptionNew();
  },
  methods: {
    setValue: function () {
      this.getSelectSipSettingData = {};
    },
    closeAssignNumberModal() {
      this.getSelectSipSettingData = {};
      this.showSipSettingSelectList = false;
      // this.closedAssignedModal();
      this.$emit("close-asign-modal");
    },
    async getSubscriptionNew() {
      var payload = {
        didStatus: "DID_ACTIVATED",
        viewIndex: 1,
        viewSize: 50,
        partyId: this.userDetail.partyId,
      };
      try {
        let response = await numberManagerAPI.getDidNumberDetailSubscriptionNew(
          payload
        );
        if (response) {
          this.subscriptionNewList = response.numberList;
        }
      } catch (error) {
        console.log("====error===", error);
      }
    },
    closedAssignedModal() {
      this.$emit("closedAssignedModal");
    },
    getSelectSipSettingType() {
      if (this.selectSipSettingInput == "new") {
        this.showSipSettingPhoneInput = true;
        this.showSipSettingSelectList = false;
        this.getSelectSipSettingData = {};
      }
      if (this.selectSipSettingInput == "existing") {
        this.showSipSettingPhoneInput = false;
        this.showSipSettingSelectList = true;
        this.getSelectSipSettingData = {};
      }
    },
    async assignSipSetting() {
      if (
        this.getSelectSipSettingData.deviceName &&
        this.getSelectSipSettingData.callerId
      ) {
        this.isLoading = true;
        var didToServerObj = {
          accountId: this.userDetail.accountId,
          authToken: this.userDetail.authToken,
          callflowId: this.userDetail.callflowId,
          inventoryItemId: this.assigningNumberData.inventoryItemId,
          didNumber: this.assigningNumberData.didNumber,
          sipName: this.selectedDeviceName,
        };
        if (this.getSelectSipSettingData.deviceName) {
          didToServerObj.sipName = this.getSelectSipSettingData.deviceName;
        }
        if (this.getSelectSipSettingData.callerId) {
          didToServerObj.callerId = this.getSelectSipSettingData.callerId;
        }
        try {
          let response = await numberManagerAPI.assignSip(didToServerObj);
          this.getSelectSipSettingData = {};
          this.$root.$emit("SHOW_SNACKBAR", {
            text: response.responseMessage,
            color: "success",
          });
          this.closedAssignedModal();

          this.isLoading = false;
        } catch (error) {
          // console.log("====error===", error);
          this.isLoading = false;
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "something wrong!",
            color: "error",
          });
        }
      } else {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Fill the fields!",
          color: "error",
        });
      }
    },
  },
};
</script>


