<template>
  <v-card>
    <v-toolbar elevation="0"
      ><v-card-title class="px-0"> Assigning Number </v-card-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeAssignNumberModal">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text class="pb-0">
      <p class="pt-2 pb-0">
        <b>Choose one of the following forward Options:</b>
      </p>
      <!-- Assigning Numbers Options -->
      <v-radio-group
        v-model="selectOption.forwardType"
        @change="clickOnSwitchServer()"
        class="pt-0 pb-2"
      >
        <v-radio value="CELL_PHONE">
          <template v-slot:label>
            <div class="black--text">Regular phone</div>
          </template>
        </v-radio>
        <v-radio value="Duckduckgo">
          <template v-slot:label>
            <div class="black--text">
              DIDnPBX <small> (Our Free Fully Hosted PBX)</small>
            </div>
          </template>
        </v-radio>
        <v-radio value="SIP_URI">
          <template v-slot:label>
            <div class="black--text">Our Own Softswitch</div>
          </template>
        </v-radio>
        <v-radio value="SIP">
          <template v-slot:label>
            <div class="black--text">SIPSETTING</div>
          </template>
        </v-radio>
        <v-radio value="FAX2EMAIL">
          <template v-slot:label>
            <div class="black--text">Fax2Email</div>
          </template>
        </v-radio>
      </v-radio-group>
      <!-- Assigning Regular Numbers type Options -->
      <span v-if="selectOption.forwardType == 'CELL_PHONE'">
        <v-col cols="12" class="py-0">
          <v-card-title class="pt-0 pl-0 black--text pb-5 title"
            >Regular phone</v-card-title
          >
          <v-text-field
            v-model="selectOption.sipName"
            label="Enter device name"
            type="text"
            append-icon="user"
            outlined
            rounded
            dense
            readonly
          ></v-text-field>
          <v-text-field
            v-model="selectOption.deviceDetails.call_forward.number"
            label="Enter Complete number with country code"
            type="text"
            append-icon="call"
            outlined
            rounded
            readonly
            dense
          ></v-text-field>
          <v-divider></v-divider>
          <v-card-actions class="py-3">
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="px-4"
              outlined
              @click="closeAssignNumberModal"
            >
              Cancel
            </v-btn>
            <!-- <v-btn class="px-4 ml-4 primary-btn" outlined @click="assignNumber">
              Assign
            </v-btn> -->
          </v-card-actions>
        </v-col>
      </span>
    </v-card-text>

    <!-- Soft switch Input -->
    <v-card-text v-if="selectOption.forwardType == 'SIP_URI'">
      <v-col cols="12" lg="12">
        <v-text-field
          v-model="selectOption.serverName"
          label="Trunk Name"
          append-icon="person"
          disabled
          outlined
          rounded
          dense
        ></v-text-field>
        <v-text-field
          v-model="ipAddress"
          label="Ip"
          append-icon="person"
          outlined
          disabled
          rounded
          dense
        ></v-text-field>
        <v-select
            v-model="inbound_format"
            :items="inboundFormatOptions"
            item-text="text"
            item-value="value"
            label="Select Inbound Format"
            append-icon="person"
            outlined
            disabled
            rounded
            dense
        ></v-select>
         <!-- Conditionally show the text box when "username/Extension@Ip" is selected -->
        <v-text-field
            v-if="inbound_format === 'username'"
            v-model="username"
            label="Enter Username or Extension"
            placeholder="Up to 15 alphanumeric"
            append-icon="person"
            outlined
            disabled
            rounded
            dense
        ></v-text-field>
        <v-card-actions class="py-3">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="px-4"
            outlined
            @click="closeAssignNumberModal"
          >
            Cancel
          </v-btn>
          <v-btn
            class="px-4 ml-4 primary-btn"
            outlined
            @click="showSoftSwitch()"
          >
            Edit Server
          </v-btn>
        </v-card-actions> 
        <section v-show="showSoftswitchRadio">
          <createSoftSwitch
            :assigningNumberData="assigningNumberData"
            :sipUriDeviceData="sipUriDeviceData"
            @close-asign-modal="closedModal"
            @closedAssignedModal="closedSavedAssignedModal"
            ref="closeSoftSwitch"
          />
        </section>
      </v-col>
    </v-card-text>

    <!-- Sip Setting Input -->
    <v-card-text v-if="selectOption.forwardType == 'SIP'">
      <v-col cols="12" lg="12">
        <v-text-field
          v-model="selectOption.sipName"
          label="Device Nickname"
          append-icon="person"
          disabled
          outlined
          rounded
          dense
        ></v-text-field>
        <v-text-field
          v-model="selectOption.callerId"
          label="Caller id number"
          append-icon="call"
          disabled
          outlined
          rounded
          dense
        ></v-text-field>
        <v-col cols="12">
          <v-row>
            <v-text-field
              v-model="selectOption.userName"
              label="Username"
              append-icon="person"
              disabled
              outlined
              rounded
              dense
            ></v-text-field>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="copied"
                  v-clipboard:copy="selectOption.userName"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mt-2 pl-2"> content_copy </v-icon>
                </v-btn>
              </template>
              <span>Copy</span>
            </v-tooltip>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-text-field
              v-model="selectOption.password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              label="Password"
              outlined
              rounded
              dense
            ></v-text-field>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="copied"
                  v-clipboard:copy="selectOption.password"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mt-2 pl-2"> content_copy </v-icon>
                </v-btn>
              </template>
              <span>Copy</span>
            </v-tooltip>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-text-field
              v-model="userDetail.realm"
              label="Realm//Sip Address"
              append-icon="person"
              disabled
              outlined
              rounded
              dense
            ></v-text-field>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="copied"
                  v-clipboard:copy="userDetail.realm"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mt-2 pl-2"> content_copy </v-icon>
                </v-btn>
              </template>
              <span>Copy</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-col>
      <v-divider></v-divider>
      <v-card-actions class="pt-4 pb-0">
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          class="px-4"
          outlined
          dark
          @click="closeAssignNumberModal()"
        >
          Cancel
        </v-btn>
        <v-btn class="px-4 ml-4 primary-btn" outlined @click="editSipSetting()">
          Edit Device
        </v-btn>
      </v-card-actions>
    </v-card-text>
    <!--SipSettingSelectList -->
    <v-card-text class="pb-0" v-show="showSipSettingSelectList">
      <v-col cols="12">
        <sipSettingVue
          :assigningNumberData="assigningNumberData"
          :softPhoneDeviceData="softPhoneDeviceData"
          @closedAssignedModal="closedAssignedModal"
          @close-asign-modal="closedModal"
        />
      </v-col>
    </v-card-text>
    <!-- Assigning Fax2 Email -->
    <v-card-text
      class="pt-0 pb-0"
      v-show="selectOption.forwardType == 'FAX2EMAIL'"
    >
      <v-row>
        <v-col class="pt-0" cols="12" lg="12">
          <v-text-field
            v-model="selectOption.faxboxName"
            label="email"
            outlined
            rounded
            dense
            append-icon="email"
            disabled
          ></v-text-field>
          <!-- <label class="pt-0 pl-2">Existing Fax Box</label>
          <v-select
            class="mt-2"
            :items="faxBoxList"
            item-text="name"
            item-value="name"
            v-model="faxEmail"
            label="Select Email"
            append-icon="call"
            outlined
            rounded
            dense
          ></v-select> -->
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="px-4"
              outlined
              @click="closeAssignNumberModal"
              dark
            >
              Cancel
            </v-btn>
            <!-- <v-btn
              class="px-4 ml-4 primary-btn"
              outlined
              :loading="isLoading"
              @click="assignFaxEmail"
            >
              Assign
            </v-btn> -->
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions v-show="hideBtn">
      <v-btn color="info" class="mx-auto px-4" dark> Renew </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import numberManagerAPI from "@/services/numberManagerAPI.js";
import createSoftSwitch from "./common/createSoftSwitch.vue";
import sipSettingVue from "./common/sipSetting.vue";

export default {
  components: {
    createSoftSwitch,
    sipSettingVue,
  },
  props: [
    "assigningNumberData",
    "cellPhoneDeviceData",
    "sipUriDeviceData",
    "softPhoneDeviceData",
    "faxBoxList",
    "selectOption",
    "username",
    "ipAddress",
    "inbound_format"
  ],
  data() {
    return {
      inboundFormatOptions: [
        { text: "DID@IP", value: "strip_plus" },
        { text: "+DID@IP", value: "e164" },
        { text: "username/Extension@Ip", value: "username" }
      ],
      getSelectedOption: "",
      hideBtn: false,
      showPassword: false,
      selectAssigningTypeOption: "new",
      selectSoftSwitchInput: "",
      userDetail: {},
      showSipSettingSelectList: false,
      showSoftswitchRadio: false,
      faxEmail: "",
      isLoading: false,
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    // this.getSelectedOption = this.selectOption;
  },
  methods: {
    clickOnSwitchServer() {
      this.$emit("switchServerModal");
    },
    closeAssignNumberModal() {
      // this.$refs.closeSoftSwitch.setValue();
      this.showSoftswitchRadio = false;
      this.showSipSettingSelectList = false;
      this.faxEmail = "";
      this.$emit("closeModal");
    },
    closedSavedAssignedModal() {
      this.$emit("closedOnAssignedModal");
      this.showSoftswitchRadio = false;
    },
    closedAssignedModal() {
      this.showSoftswitchRadio = false;
      this.showSipSettingSelectList = false;
      this.$emit("closedOnAssignedModal");
    },
    closedModal() {
      this.showSoftswitchRadio = false;
      this.showSipSettingSelectList = false;
    },
    closeSoftswitch() {
      this.showSoftswitchRadio = false;
    },
    showSoftSwitch() {
      this.showSoftswitchRadio = true;
    },
    copied() {
      this.$root.$emit("SHOW_SNACKBAR", {
        text: "Copied",
        color: "success",
      });
    },
    editSipSetting() {
      this.showSipSettingSelectList = true;
      // this.selectOption.forwardType = false;
    },
    closeSipSetting() {
      this.showSipSettingSelectList = false;
    },
  },
};
</script>        